import React, { Component } from "react";
import "../styles/About.css";
import Michael from "../assets/about_michael.gif";
class About extends Component {
  render() {
    return (
      <div>
        <div id="about-body">
          <img src={Michael} alt="" className="about-michael-img fade-in one" />
          <div id="about-info">
            <h1 className="fade-in two">ABOUT MICHAEL SMITH</h1>
            <p className="fade-in three">
              I love creating things; it is an important part of my life,
              whether it is music, art, or woodworking. After years of working
              in the tech industry building computers and supporting networks, I
              decided to bring my love for creativity and technology together
              and become a Web Developer. I recently completed the Web
              Development Immersive program at the Denver based school
              Galvanize. The program not only taught me how to code but also how
              to teach myself and to face new challenges head-on.
              <br />
              <br />
              My background in music has demonstrated to me that there is more
              than one way to approach a problem. This belief has helped me
              immensely as it has carried over to the development process. I
              continue to think outside the box and create apps and sites that
              are clean, simple, and fun to use. During my time at Galvanize, I
              worked on projects to bridge the gap between function and design.
              I enjoy developing front-end applications in frameworks such as
              React.js and React Native. I am also familiar with backend
              technologies such as Node.js, Express, and Knex.js. I find value
              and joy in starting a project and seeing it through to the end.
              <br />
              <br />
              In my free time, I love playing drums, collecting and listening to
              records, spending time with my wife and two daughters, and
              woodworking. These activities influence my work in a positive way
              by feeding my soul and providing inspiration.
              <br />
              <br />
              I embrace the challenges of this industry and am excited
              about the new possibilities it has to offer. I am currently
              looking to join a team who is just as excited as I am. I would
              love to connect with you if you would like to discuss working
              together or if you are interested in collaborating on your next
              project.
            </p>
            <div className="about-download-container fade-in three">
              <a href={require("../assets/Michael_Smith_Resume.pdf")} download>
                <span className="about-icon far fa-file" />
                <div className="about-download-text">
                  <h3>DOWNLOAD RESUME</h3>
                  <span>Michael Smith</span>
                </div>
              </a>
              <a
                href="https://youtu.be/HKYp97iAoQc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="about-icon fas fa-video" />
                <div className="about-download-text">
                  <h3>CAPSTONE PRESENTATION</h3>
                  <span>Michael Smith</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
