import React, { Component } from "react";
import ModalTechnologies from "./ModalTechnologies.jsx";
import ModalOtherTechnologies from "./ModalOtherTechnologies.jsx";

class DevelopmentModal extends Component {
  render() {
    const project = this.props.modalProject;
    return (
      <div id="development-modal" className={`${this.props.setFade}`}>
        <div
          className="close-button"
          onClick={this.props.closeModal.bind(this)}
        >
          <h3>CLOSE</h3>
        </div>
        <div id="development-modal-body">
          <div id="development-modal-info">
            <h1>{project.title}</h1>
            <div id="development-modal-image">
              <img src={require(`../assets/${project.project_image}`)} alt="" />
            </div>
            <h2>DESCRIPTION</h2>
            <p>{project.short_description}</p>
            <h2>PURPOSE</h2>
            <p>{project.purpose_description}</p>
            <h2>TECHNOLOGIES USED</h2>
            <div id="development-modal-technologies">
              <ModalTechnologies skills={project.technologies} />
            </div>
            <h2>OTHER TECHNOLOGIES USED</h2>
            <div id="modal-other-technologies">
              <ul>
                <ModalOtherTechnologies
                  otherSkills={project.other_technologies}
                />
              </ul>
            </div>
            <h2>DEMO/CODE</h2>
            <p>{project.demo_description}</p>
            <div id="modal-icons-container">
              <div className="modal-icons">
                <a href={project.demo_url} target="_blank" rel="noopener noreferrer">
                  <i className={`modal-icon ${project.demo_icon}`} />
                  <h4>{project.demo_title}</h4>
                </a>
              </div>
              <div className="modal-icons">
                <a href={project.github_url} target="_blank" rel="noopener noreferrer">
                  <i className="modal-icon fab fa-github" />
                  <h4>GitHub</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DevelopmentModal;
