import React, { Component } from "react";

class ModalOtherTechnologies extends Component {
  render() {
      const skills = this.props.otherSkills;
      return(skills.map(skill => {
        return (
            <li key={skill}>{skill}</li>
          );
      })
      )
  }
}

export default ModalOtherTechnologies;
