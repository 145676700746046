import React, { Component } from "react";

class MusicVideos extends Component {
  render() {
    return (
      <div id="music-video-container">
        <h1>MUSIC VIDEOS</h1>
        <div id="music-videos">
          <div className="music-media-video">
            <iframe
              width="560"
              height="315"
              title="You Need"
              src={`https://www.youtube.com/embed/UV2EzBpSIJU?rel=0&amp;showinfo=0`}
              allow="autoplay; encrypted-media"
            />
          </div>
          <div className="music-media-video">
            <iframe
              width="560"
              height="315"
              title="Looking So Lovely"
              src={`https://www.youtube.com/embed/MmPKAauP6SQ?rel=0&amp;showinfo=0`}
              allow="autoplay; encrypted-media"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MusicVideos;
