import React, { Component } from "react";
import { HashRouter as Router } from "react-router-dom";
import "./App.css";
import Header from "./components/Header.jsx";
import Container from "./Container.jsx";
import Footer from "./components/Footer.jsx";

class App extends Component {
  render() {
    return (
      <Router>
        <div id="app">
          <Header />
          <Container />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
