import React, { Component } from "react";
import HTML_Logo from "../assets/html5_logo.png";
import CSS_Logo from "../assets/css3_logo.png";
import JS_Logo from "../assets/JS_logo.png";
import React_Logo from "../assets/react_logo.png";
import Express_Logo from "../assets/express_logo.png";
import Knex_Logo from "../assets/knex_logo.png";
import Node_Logo from "../assets/node_logo.png";
import PostgreSQL_Logo from "../assets/postgreSQL_logo.png";
import Cypress_Logo from "../assets/cypress_logo.png";

class DevelopmentInfo extends Component {
  render() {
    return (
      <div id="development-header-info-container">
        <div id="development-header-info" className="fade-in three">
          <h2>SKILLS</h2>
          <p>
            I am Full Stack Web Developer skilled in the following technologies:
          </p>
          <div id="development-header-skills">
            <div className="dev-skill">
              <img src={HTML_Logo} alt="html5 logo" />
              <h4>HTML5</h4>
            </div>
            <div className="dev-skill">
              <img src={CSS_Logo} alt="html5 logo" />
              <h4>CSS3</h4>
            </div>
            <div className="dev-skill">
              <img src={JS_Logo} alt="html5 logo" />
              <h4>JavaScript</h4>
            </div>
            <div className="dev-skill">
              <img src={React_Logo} alt="html5 logo" />
              <h4>React.js</h4>
            </div>
            <div className="dev-skill">
              <img src={React_Logo} alt="html5 logo" />
              <h4>React Native</h4>
            </div>
            <div className="dev-skill">
              <img src={Node_Logo} alt="html5 logo" />
              <h4>Node</h4>
            </div>
            <div className="dev-skill">
              <img src={PostgreSQL_Logo} alt="html5 logo" />
              <h4>PostgreSQL</h4>
            </div>
            <div className="dev-skill">
              <img src={Express_Logo} alt="html5 logo" />
              <h4>Express</h4>
            </div>
            <div className="dev-skill">
              <img src={Knex_Logo} alt="html5 logo" />
              <h4>Knex</h4>
            </div>
            <div className="dev-skill">
              <img src={Cypress_Logo} alt="html5 logo" />
              <h4>Cypress</h4>
            </div>
          </div>
          <p>Check out some of my work below.</p>
        </div>
      </div>
    );
  }
}

export default DevelopmentInfo;
