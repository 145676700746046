import React, { Component } from "react";
import "../styles/Music.css";
import MusicHeader from "../components/MusicHeader.jsx";
import MusicInfo from "../components/MusicInfo.jsx";
import MusicTracks from "../components/MusicTracks.jsx";
import MusicAlbums from "../components/MusicAlbums.jsx";
import MusicVideos from "../components/MusicVideos.jsx";

class Music extends Component {
  render() {
    return (
      <div id="music">
        <MusicHeader />
        <div id="music-body" className="fade-in three">
          <div id="track-info-container">
            <MusicInfo />
            <MusicTracks />
          </div>
          <MusicAlbums />
          <MusicVideos />
        </div>
      </div>
    );
  }
}

export default Music;
