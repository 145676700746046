import React, { Component } from "react";
import "../styles/Footer.css";

class Footer extends Component {
  render() {
    return (
      <div id="footer" className="fade-in four">
        <div className="copyright">
          <p>&copy; 2018 NEWCODESMITH</p>
        </div>
        <div id="footer-icons">
          <a
            href="https://github.com/newcodesmith?tab=stars"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-icon"
          >
            <i className="fab fa-github" />
          </a>
          <a
            href="https://www.linkedin.com/in/newcodesmith/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-icon"
          >
            <i className="fab fa-linkedin" />
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
