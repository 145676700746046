import React, { Component } from "react";

class MusicTracks extends Component {
  render() {
    return (
      <div id="music-tracks-container">
        <h1>FEATURED TRACKS</h1>
        <div id="music-media-tracks">
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="Gulf Of Mexico"
              src="https://bandcamp.com/EmbeddedPlayer/album=2859114674/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=121160162/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="I Won't Be Long"
              src="https://bandcamp.com/EmbeddedPlayer/album=2859114674/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=396789892/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="When Summer Comes"
              src="https://bandcamp.com/EmbeddedPlayer/album=2859114674/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=1428061528/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="Mile High City"
              src="https://bandcamp.com/EmbeddedPlayer/album=2859114674/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=1302954794/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="Story"
              src="https://bandcamp.com/EmbeddedPlayer/album=2955115250/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=1750564416/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="Queen of the Temps"
              src="https://bandcamp.com/EmbeddedPlayer/album=2955115250/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=499785268/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="Candles"
              src="https://bandcamp.com/EmbeddedPlayer/album=2955115250/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=176420137/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="On to you"
              src="https://bandcamp.com/EmbeddedPlayer/album=2066283725/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=3207121318/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="Archetype"
              src="https://bandcamp.com/EmbeddedPlayer/album=2066283725/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=2651405641/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="Payroll"
              src="https://bandcamp.com/EmbeddedPlayer/album=2066283725/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=1807483054/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="The Roaring Town"
              src="https://bandcamp.com/EmbeddedPlayer/album=2066283725/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=407239696/transparent=true/"
            />
          </div>
          <div className="music-track">
            <iframe
              width="100%"
              height="42px"
              title="Beyond the Line"
              src="https://bandcamp.com/EmbeddedPlayer/album=2066283725/size=small/bgcol=333333/linkcol=70a9a1/artwork=none/track=945765663/transparent=true/"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MusicTracks;
