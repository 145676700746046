import React, { Component } from "react";
import CPRDrums from "../assets/cpr_drums.jpg";

class MusicInfo extends Component {
  render() {
    return (
      <div id="music-info">
        <p>
          Music has always been a large part of my life. Whether I am collecting
          and listening to records or playing music, I always yearn for more.
          Over the years, I have had the pleasure of recording 6 full length
          albums with various groups, filming a few music videos, and opening for
          Flobots, Lotus, El Ten Eleven, Walk the Moon, and Joan Osborne.
          <br />
          <br />
          Check out some of the music I have helped to create.
        </p>
        <div id="music-info-image">
          <img src={CPRDrums} alt="" />
        </div>
      </div>
    );
  }
}

export default MusicInfo;
