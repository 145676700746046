import React, { Component } from "react";

class DevelopmentHeader extends Component {
  render() {
    return (
      <div id="development-header">
        <div id="development-header-background" className="fade-in one">
          <svg width="100%" height="100%">
            <mask id="development-header-mask">
              <rect width="100%" height="100%" fill="#161616" />
              <text
                x="-350"
                y="125"
                className="development-header-background-text"
                fill="#eee"
              >
                DEVELOPMENT
              </text>
              <text
                x="-50"
                y="450"
                className="development-header-background-text"
                fill="#eee"
              >
                DEVELOPMENT DEVELOPMENT
              </text>
              <text
                x="-750"
                y="775"
                className="development-header-background-text"
                fill="#eee"
              >
                DEVELOPMENT DEVELOPMENT
              </text>
            </mask>
            <rect
              width="100%"
              height="100%"
              mask="url(#development-header-mask)"
              fill="#161616"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default DevelopmentHeader;
