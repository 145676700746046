import React, { Component } from "react";
import "../styles/Header.css";
import Logo from "../assets/new_code_smith_logo_white.png";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <div className="nav-bar">
        <Link to={"/"}>
          <div className="nav-logo">
            <img className="name-logo" src={Logo} alt="" height="65px" />
            <h1>new code smith</h1>
          </div>
        </Link>

        <div className="nav-bar-buttons">
          <Link to={"/about"}>about</Link>
          <Link to={"/development"}>development</Link>
          <Link to={"/music"}>music</Link>
          <Link to={"/contact"}>contact</Link>
        </div>
      </div>
    );
  }
}

export default Header;
