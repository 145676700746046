import React, { Component } from "react";

class ModalTechnologies extends Component {
  render() {
      const skills = this.props.skills;
      return(skills.map(skill => {
        return (
            <div key={skill.name} className="dev-skill">
                <img src={require(`../assets/${skill.icon}`)} alt={skill.name}/>
                <h4>{skill.name}</h4>
            </div>
          );
      })
      )
  }
}

export default ModalTechnologies;
