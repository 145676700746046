var Projects = [
  {
    title: "Vinyl Finder",
    project_image: "vinyl_finder.png",
    short_description: "A record store finder mobile app built with React.",
    purpose_description:
      "Finding a record store in your area is not always easy. That's why I have created this mobile app to help you locate stores in your area. You can also read and leave reviews about these stores. This was my Capstone project for Galvanize.",
    technologies: [
      {
        name: "React Native",
        icon: "react_logo.png"
      },
      {
        name: "Express",
        icon: "express_logo.png"
      },
      {
        name: "Knex",
        icon: "knex_logo.png"
      },
      {
        name: "PostgreSQL",
        icon: "postgreSQL_logo.png"
      },
      {
        name: "Node",
        icon: "node_logo.png"
      }
    ],
    other_technologies: ["Expo", "Facebook API", "Heroku"],
    demo_description:
      "This project is a mobile only app. To see it in action, check out the following video. You can also view the code on GitHub.",
    demo_icon: "fas fa-video",
    demo_title: "Demo Video",
    demo_url: "https://www.youtube.com/watch?v=GzqG7FXCtC4&feature=youtu.be",
    github_url: "https://github.com/newcodesmith/record-store-locator-frontend"
  },
  {
    title: "Music & Cocktails",
    project_image: "music_cocktails.png",
    short_description: "A mock monthly record club web app built with React.js",
    purpose_description:
    "I created this app as a mock monthly record club that features 6 curated albums from different genres. Each Album is paired with a curated drink.  The app provides information on each album and drink.  You can also preview the album using Spotify.",
        technologies: [
      {
        name: "React.js",
        icon: "react_logo.png"
      },
      {
        name: "CSS",
        icon: "css3_logo.png"
      },
      {
        name: "Express",
        icon: "express_logo.png"
      },
      {
        name: "Knex",
        icon: "knex_logo.png"
      },
      {
        name: "PostgreSQL",
        icon: "postgreSQL_logo.png"
      }
    ],
    other_technologies: ["Spotify API"],
    demo_description:
      "Check out the live site. You can also view the code on GitHub.",
    demo_icon: "fas fa-globe",
    demo_title: "Live Site",
    demo_url: "https://music-and-cocktails.firebaseapp.com/",
    github_url: "https://github.com/newcodesmith/music-and-cocktails-front"
  },
  {
    title: " Top 500 Albums",
    project_image: "top_500.png",
    short_description:
      "A web app displaying the top 500 rated albums by Rolling Stone and NME. Built with JavaScript.",
    purpose_description:
      "This website features the top 500 albums voted by US based Rolling Stone’s magazine and UK based NME.  For music lovers, it will be a real treat to see the difference in these lists!",
    technologies: [
      {
        name: "HTML",
        icon: "html5_logo.png"
      },
      {
        name: "CSS",
        icon: "css3_logo.png"
      },
      {
        name: "JavaScript",
        icon: "JS_logo.png"
      }
    ],
    other_technologies: ["Discogs API"],
    demo_description:
      "Check out the live site. You can also view the code on GitHub.",
    demo_icon: "fas fa-globe",
    demo_title: "Live Site",
    demo_url: "https://top-albums-list.firebaseapp.com/",
    github_url: "https://github.com/newcodesmith/top-500-albums"
  },
  {
    title: "Rachio",
    project_image: "rachio.png",
    short_description:
      "An app for your Rachio smart sprinkler controler.  Built using React.js",
    purpose_description:
      "This was a code challenge using Rachio Smart Sprinkler Controller API. This app allows you to run selected sprinkler zones on any of your Rachio devices.",
    technologies: [
      {
        name: "React.js",
        icon: "react_logo.png"
      },
      {
        name: "CSS",
        icon: "css3_logo.png"
      },
      {
        name: "Node",
        icon: "node_logo.png"
      },
      {
        name: "Express",
        icon: "express_logo.png"
      }
    ],
    other_technologies: ["Webpack", "Babel", "Rachio API"],
    demo_description:
      "This site is not available live due to the use of a test account. Please enjoy the demo video. You can also view the code on GitHub.",
    demo_icon: "fas fa-video",
    demo_title: "Demo Video",
    demo_url: "https://youtu.be/8_gkbgTjqpU",
    github_url: "https://github.com/newcodesmith/Rachio"
  },
  {
    title: "gEvents Locator",
    project_image: "gEvents.png",
    short_description:
      "A Group Project web app displaying Galvanize Events with a map of location. Built with React.js.",
    purpose_description:
      "It’s not always easy to find your way around Galvanize.  With the G-Events Locator App, users have the ability to see a list of upcoming events and even view the corresponding floor map and directions.  Finding Galvanize events have never been easier!",
    technologies: [
      {
        name: "React.js",
        icon: "react_logo.png"
      },
      {
        name: "CSS",
        icon: "css3_logo.png"
      },
      {
        name: "Node",
        icon: "node_logo.png"
      },
      {
        name: "Knex",
        icon: "knex_logo.png"
      },
      {
        name: "PostgreSQL",
        icon: "postgreSQL_logo.png"
      },
      {
        name: "Express",
        icon: "express_logo.png"
      }
    ],
    other_technologies: ["Bootstrap || materialize", "Cheerio.js"],
    demo_description:
    "Check out a demo video of this app. You can also view the code on GitHub.",    demo_icon: "fas fa-video",
    demo_title: "Demo Video",
    demo_url: "http://jmh-g-events-slide.surge.sh/g-events-demo-2.2.mp4",
    github_url: "https://github.com/newcodesmith/G-Events-Frontend"
  },
  {
    title: "First Personal Site",
    project_image: "first_site.png",
    short_description: "An exercise in building my first site. Built with HTML",
    purpose_description:
    "During my time at Galvanize, not only did I learn how to code but, I learned a good foundation in setting goals, the process of learning, and networking. This app was my first project assignment and displays the soft skills I can bring to an organization. The assignment was to build a static site using HTML and CSS.",    technologies: [
      {
        name: "HTML",
        icon: "html5_logo.png"
      },
      {
        name: "CSS",
        icon: "css3_logo.png"
      }
    ],
    other_technologies: ["jQuery"],
    demo_description:
      "Check out the live site. You can also view the code on GitHub.",
    demo_icon: "fas fa-globe",
    demo_title: "Live Site",
    demo_url: "https://michael-smith-site.firebaseapp.com/",
    github_url: "https://github.com/newcodesmith/first-personal-site"
  }
];

export default Projects;
