import React, { Component } from "react";
import "../styles/Development.css";
import DevelopmentHeader from "../components/DevelopmentHeader.jsx";
import DevelopmentProjects from "../components/DevelopmentProjects.jsx";
import DevelopmentInfo from "../components/DevelopmentInfo.jsx";
import DevelopmentModal from "../components/DevelopmentModal.jsx";
import Projects from "../Projects.js";

class Development extends Component {
  constructor() {
    super();
    this.state = {
      projects: Projects,
      modalProject: null,
      modalIsShown: false,
      setFade: "fade-in"
    };
  }

  openModal = project => {
    this.setState({ modalProject: project });
    this.setState({ modalIsShown: true });
    document.body.style.overflow = 'hidden';
  };

  closeModal = (event) => {
    event.preventDefault();
    this.setState({ setFade: "fade-out" });
    document.body.style.overflow = 'auto';
    setTimeout(() => {
      this.setState({ modalIsShown: false });
      this.setState({ setFade: "fade-in" });
    }, 1000);
  };

  render() {
    return (
      <div id="development-container">
        <div>
          {this.state.modalIsShown ? (
            <DevelopmentModal
              closeModal={this.closeModal}
              modalProject={this.state.modalProject}
              setFade={this.state.setFade}
            />
          ) : null}
        </div>
        <div>
          <div id="development-header-container">
            <DevelopmentHeader />
            <div id="development-header-title-info-container">
              <div id="development-header-title">
                <h1 className="fade-in two">DEVELOPMENT</h1>
              </div>
              <DevelopmentInfo />
            </div>
          </div>
          <div id="development-body">
            <div id="development-projects-container" className="fade-in four">
              <h1>PROJECTS</h1>
              <div id="development-projects">
                <DevelopmentProjects
                  openModal={this.openModal}
                  projects={this.state.projects}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Development;
