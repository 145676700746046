import React, { Component } from "react";
import "../styles/Contact.css";
import Denver from "../assets/denver_motion.gif";

class Contact extends Component {
  render() {
    return (
      <div id="contact-body">
        <div id="contact-header-container">
          <div id="contact-header">
            <div>
              <img src={Denver} alt="" />
              <h1 className="fade-in one">CONTACT</h1>
              <div>
                <p className="fade-in two">
                  Let's make something great together!
                </p>
              </div>
            </div>
          </div>
          <div id="contact-info" className="fade-in three">
            <div id="contact-form">
              <form
                method="POST"
                action="https://formspree.io/mf.smith@live.com"
              >
                <h1>Send me a message</h1>
                <input type="email" name="email" placeholder="Your email" />
                <textarea name="message" placeholder="Your message" />
                <button type="submit">SEND</button>
              </form>
            </div>
            <div className="vertical-line" />
            <div className="contact-method">
              <div className="contact-method-info">
                <a
                  href={require("../assets/Michael_Smith_Resume.pdf")}
                  download
                >
                  <span className="icon far fa-file" />
                  <div className="contact-method-info-text">
                    <h3>Download Resume</h3>
                    <span>Michael Smith</span>
                  </div>
                </a>
              </div>
              <div className="contact-method-info">
                <a href="mailto:mf.smith@live.com">
                  <span className="icon fa fa-envelope" />
                  <div className="contact-method-info-text">
                    <h3>Email</h3>
                    <span>mf.smith@live.com</span>
                  </div>
                </a>
              </div>
              <div className="contact-method-info">
                  <span className="icon fa fa-phone" />
                  <div className="contact-method-info-text">
                    <h3>Phone</h3>
                    <span>Send me a message to setup a call</span>
                  </div>
              </div>
              <div className="contact-method-info">
              <a
                  href="https://www.google.com/maps/place/Parker,+CO/@39.5080344,-104.835729,12z/data=!3m1!4b1!4m5!3m4!1s0x876c91ce239a3d55:0xd49aeed43d2e2426!8m2!3d39.5186002!4d-104.7613633"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                <span className="icon fa fa-map-marker" />
                <div className="contact-method-info-text">
                  <h3>Location</h3>
                  <span>
                    Parker, Colorado
                    <br />
                    United States of America
                  </span>
                </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
