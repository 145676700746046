import React, { Component } from "react";

class DevelopmentProjects extends Component {
  render() {
    const projectsData = this.props.projects;

    return projectsData.map(project => {
      return (
        <figure 
        key={project.title}
        className="project-tile"
        onClick={this.props.openModal.bind(this, project)}
        >
          <img src={require(`../assets/${project.project_image}`)}  alt={project.title} className="blackandwhite" />
          <figcaption>
            <div>
              <h2>{project.title}</h2>
              <h3>MORE</h3>
            </div>
            <div>
              <p>{project.short_description}</p>
              <h4>INFO</h4>
            </div>
          </figcaption>
        </figure>
      );
    });
  }
}

export default DevelopmentProjects;
