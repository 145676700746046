import React, { Component } from "react";

class MusicAlbums extends Component {
  render() {
    return (
      <div id="music-albums-container">
        <h1>ALBUMS</h1>
        <div id="music-albums">
          <div className="music-media-audio">
            <iframe
              width="350px"
              height="350x"
              title="Suburban Blues"
              src="https://bandcamp.com/EmbeddedPlayer/album=2859114674/size=large/bgcol=333333/linkcol=70a9a1/minimal=true/transparent=true/"
              seamless
            />
            <a
              href="https://panastral.bandcamp.com/album/suburban-blues"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>PAN ASTRAL - Suburban Blues</h2>
            </a>
          </div>
          <div className="music-media-audio">
            <iframe
              width="350px"
              height="350x"
              title="Mary Can Dream"
              src="https://bandcamp.com/EmbeddedPlayer/album=2955115250/size=large/bgcol=333333/linkcol=70a9a1/minimal=true/transparent=true/"
              seamless
            />
            <a
              href="https://panastral.bandcamp.com/album/mary-can-dream"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>PAN ASTRAL - Mary Can Dream</h2>
            </a>
          </div>
          <div className="music-media-audio">
            <iframe
              width="350px"
              height="350x"
              title="On To You"
              src="https://bandcamp.com/EmbeddedPlayer/album=2066283725/size=large/bgcol=333333/linkcol=70a9a1/minimal=true/transparent=true/"
              seamless
            />
            <a
              href="https://panastral.bandcamp.com/album/mary-can-dream"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>THE MERCURY PROJECT - On To You</h2>
            </a>
          </div>
          <div className="music-media-audio">
            <iframe
              width="350px"
              height="350x"
              title="Soapbox Jive"
              src="https://bandcamp.com/EmbeddedPlayer/album=2577163174/size=large/bgcol=333333/linkcol=70a9a1/minimal=true/transparent=true/"
              seamless
            />
            <a
              href="https://themercuryproject.bandcamp.com/album/soapbox-jive"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>THE MERCURY PROJECT - Soapbox Jive</h2>
            </a>
          </div>
          <div className="music-media-audio">
            <iframe
              width="350px"
              height="350x"
              title="A World Exist"
              src="https://bandcamp.com/EmbeddedPlayer/album=1085967855/size=large/bgcol=333333/linkcol=70a9a1/minimal=true/transparent=true/"
              seamless
            />
            <a
              href="https://themercuryproject.bandcamp.com/album/a-world-exists"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>THE MERCURY PROJECT - A World Exists</h2>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default MusicAlbums;
