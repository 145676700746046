import React, { Component } from "react";

class MusicHeader extends Component {
  render() {
    return (
      <div id="music-header">
        <h1 className="fade-in two">MUSIC</h1>
        <div id="music-header-background" className="fade-in one">
          <svg width="100%" height="100%">
            <mask id="music-header-mask">
              <rect width="100%" height="100%" fill="#eee" />
              <text x="-150" y="140" className="music-header-background-text" fill="#161616">
                MUSIC MUSIC MUSIC
              </text>
              <text x="0" y="450" className="music-header-background-text" fill="#161616">
              MUSIC MUSIC MUSIC
              </text>
              <text x="-350" y="760" className="music-header-background-text" fill="#161616">
              MUSIC MUSIC MUSIC
              </text>
            </mask>
            <rect
              width="100%"
              height="100%"
              mask="url(#music-header-mask)"
              fill="#161616"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default MusicHeader;
