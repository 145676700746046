import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../styles/home.css";
import Logo from "../assets/new_code_smith_logo_white.png";

class Home extends Component {
  render() {
    return (
      <div className="home-container">
        <div className="home-nav-bar">
          <div className="home-nav-logo">
            <img src={Logo} alt="New Code Smith Logo" height="65px" />
            <h1>new code smith</h1>
          </div>
        </div>
        <div className="wrapper">
          <Link to={"/about"} id="btn-1" className="btn">
            about
          </Link>
          <div className="dot-1" />
          <Link to={"/development"} id="btn-2" className="btn">
            development
          </Link>
          <Link to={"/music"} id="btn-3" className="btn">
            music
          </Link>
          <div className="dot-2" />
          <Link to={"/contact"} id="btn-4" className="btn">
            contact
          </Link>

          <div id="content-0" className="background" />
          <div id="content-1" className="background" />
          <div id="content-2" className="background" />
          <div id="content-3" className="background" />
          <div id="content-4" className="background" />
        </div>
      </div>
    );
  }
}

export default Home;
